.footer {
  position: relative; /* Set the position to relative */
  width: 100%;
  background-color: #343a40;
  color: white;
  padding: 20px 0;
  margin-top: auto; /* Add auto margin-top to push the footer to the bottom */
}


.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footer-content {
  text-align: center;
}

.social-icons {
  margin-top: 10px;
}

.icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

/* Additional CSS for body to push content above the footer */
body {
  margin-bottom: 80px; /* Adjust the value to accommodate the footer height */
}
