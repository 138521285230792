/* BlogListView.css */
/* BlogListView.css */

#search {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem; /* Add margin bottom for spacing */
}

#search input {
  width: 100%;
  max-width: 400px; /* Limit maximum width */
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  font-size: 1rem;
  outline: none;
}

#search input:focus {
  border-color: #3182ce; /* Change border color on focus */
  box-shadow: 0 0 0 2px rgba(49, 130, 206, 0.2); /* Add focus outline */
}

.blog-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  height: 200px; /* Adjust height as needed */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
  padding: 1rem;
}

@media (min-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
}
